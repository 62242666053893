<template>
    <div class="shop_main noMenu" style="background: #FFF">
        <div class="shop_order_main">
            <userMenu type="9" />

            <div class="shop_order_list">
                <div class="shop_order_search">
                    <el-form ref="listQuery" :inline="true" :model="listQuery" size="small" label-width="84px">
                        <el-form-item label="退款单编号：" prop="detailCode">
                            <el-input v-model="listQuery.detailCode" placeholder="请输入退款单编号"></el-input>
                        </el-form-item>
                        <el-form-item label="订单编号：" prop="detailCode">
                            <el-input v-model="listQuery.detailCode" placeholder="请输入订单编号"></el-input>
                        </el-form-item>

                        <transition name="slide-right">
                            <el-form-item label="维权状态：" v-show="isQueryShow" prop="date">
                                <el-select v-model="index" placeholder="请选择维权状态">
                                    <el-option label="1" value="1"></el-option>
                                </el-select>
                            </el-form-item>
                        </transition>


                        <transition name="slide-right">
                            <el-form-item label="下单时间：" v-show="isQueryShow" prop="date">
                                <el-date-picker
                                        v-model="listQuery.date"
                                        type="daterange"
                                        range-separator="~"
                                        start-placeholder="开始时间"
                                        end-placeholder="结束时间"
                                        value-format="yyyy-MM-dd"
                                        :picker-options="dateOptions">
                                </el-date-picker>
                            </el-form-item>
                        </transition>

                        <el-form-item class="last">
                            <el-button type="primary" :loading="isSearchLoading" @click="toSearchList">查 询</el-button>
                            <el-button @click="$refs['listQuery'].resetFields()">重 置</el-button>
                            <el-button type="text" @click="isQueryShow = false" v-if="isQueryShow">收起<i class="el-icon-arrow-up el-icon--right"></i></el-button>
                            <el-button type="text" @click="isQueryShow = true" v-else>展开<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="shop_order_data">
<!--                    <div class="goods_shop_name">-->
<!--                        <img src="../../../assets/img/shop_order.png" />-->
<!--                        <span>{{item.storeName}}</span>-->
<!--                    </div>-->
                    <div class="shop_order_table">
                        <div class="table_header">
                            <div class="item" style="flex: 3; text-align: center"><span>商品</span></div>
                            <div class="item" style="flex: 1; text-align: center"><span>单价</span></div>
                            <div class="item" style="flex: 1; text-align: center"><span>数量</span></div>
                            <div class="item" style="width: 120px; text-align: center"><span>退款金额</span></div>
                            <div class="item" style="width: 110px; text-align: center"><span>维权状态</span></div>
                            <div class="item" style="width: 148px; text-align: center"><span>操作</span></div>
                        </div>

                        <div class="table_list" v-for="(item, index) in listData" :key="index">
                            <div class="table_item">
                                <div class="table_item_header">
                                    <div class="info">
                                        <div class="text">退款单编号：{{item.detailCode}}</div>
                                        <div class="text ml20">订单号：<span style="color: #3572E2">{{item.detailCode}}</span></div>
                                        <div class="text ml20">申请时间：{{item.createdAt}}</div>
                                    </div>

                                    <div class="goods_shop_name">
                                        <img src="../../../assets/img/shop_order.png" />
                                        <span>{{item.storeName}}</span>
                                    </div>

                                    <span class="desc">仅退款</span>
                                </div>

                                <div class="table_item_body">
                                    <div class="item" style="flex: 5">
                                        <div class="goods_list" v-for="(good, i) in item.goodsInfo" :key="i">
                                            <div class="goods_list_item" style="flex: 3">
                                                <div class="goods_item">
                                                    <el-image :src="imageURL + good.goodsImageUrl" class="image" fit="contain"></el-image>
                                                    <div class="goods_item_info">
                                                        <div class="name">{{good.goodsName}}</div>
                                                        <div class="text">
                                                            <span class="mr16" v-for="(sku, j) in good.sku" :key="j">{{sku.skuKey}}：{{sku.skuValue}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="goods_list_item" style="flex: 1; text-align: center">
                                                <span class="desc">￥{{good.price}}</span>
                                            </div>
                                            <div class="goods_list_item" style="flex: 1; text-align: center">
                                                <span class="desc">×{{good.num}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item" style="width: 120px; text-align: center">
                                        <span class="desc">￥{{item.price}}</span>
                                    </div>
                                    <div class="item" style="width: 110px; text-align: center">
                                    <span class="desc">
                                        <div class="status red" v-if="item.status === 1">商家待审核</div>
                                        <div class="status yellow" v-if="item.status === 2">待发货</div>
                                        <div class="status blue" v-if="item.status === 3">已发货</div>
                                        <div class="status green" v-if="item.status === 4">已完成</div>
                                        <div class="status info" v-if="item.status === 5">已取消</div>
                                    </span>
                                    </div>

                                    <div class="item" style="width: 148px; text-align: center">
                                        <div class="btnGroup">
                                            <template v-if="item.status === 1">
                                                <el-button v-if="item.channel === 1" type="primary" @click="$router.push({path: '/shop/order/upload', query: {id: item.id}})">付款凭证上传</el-button>
                                                <el-button style="margin-left: 0" @click="toCancelOrder(item.id)">取消订单</el-button>
                                            </template>

                                            <template v-if="item.channel === 1">
                                                <template v-if="item.status === 2 || item.status === 3 || item.status === 4">
                                                    <el-button v-if="item.payStatus === 3" type="primary" style="margin-left: 0" @click="$router.push({path: '/shop/order/upload', query: {id: item.id}})">付款凭证上传</el-button>
                                                </template>

                                                <el-button type="primary" style="margin-left: 0" v-if="item.status === 3" @click="toSendOrderSure(item.id)">确认收货</el-button>

                                                <el-button v-if="item.contractStatus === 3" type="primary" style="margin-left: 0" @click="toEditContract(item)">修改合同</el-button>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <noDataView v-if="listData.length === 0"/>

                <pagination :size="listQuery.pageSize" :total="pages.total" :current="listQuery.page" :pages="pages.pages" @pageChange="pageChange" />
            </div>
        </div>

        <addContract :isPopupAdd="isPopupAddContract" :companyList="companyList" @toAddSuccess="toGetContract(), isPopupContract = true" @close="isPopupAddContract = false, isPopupContract = true" />

        <el-dialog class="popup_dialog" title="修改合同" :visible.sync="isPopupContract" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true" width="600px">
            <el-form ref="formContract" :model="formContract" :rules="rulesContract" label-position="top" size="small">
                <el-form-item label="审核信息：">
                    <span class="resText">{{formContract.desc}}</span>
                </el-form-item>

                <el-form-item label="采购合同：" prop="contractId">
                    <el-select v-model="formContract.contractId" placeholder="请选择采购合同" style="width: 84%; margin-right: 20px">
<!--                        <el-option label="暂不选择" value=""></el-option>-->
                        <el-option v-for="(item, index) in contractList" :key="index" :label="item.companyName + '(' + item.number + ')'" :value="item.id"></el-option>
                    </el-select>
                    <el-button type="primary" :loading="submitLoading" @click="isPopupContract = false, isPopupAddContract= true">添加</el-button>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" :loading="submitLoading" @click="submitFormContract('formContract')">保 存</el-button>
                    <el-button type="default" @click="isPopupContract = false">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
  import {mapState} from "vuex";
  import {getOrders, toCancelOrder, toSendOrderSure, postContract} from '../../../api/shop/orders'
  import {imageURL} from "../../../utils/config";
  import {getCompany, getContractList} from "../../../api/shop/contract";
  const pagination = () => import('../../../components/pagination/shop')
  const userMenu = () => import('../../../components/layout/shop/userMenu')
  const noDataView = () => import('../../../components/noDataView')
  const addContract = () => import('../../../components/layout/shop/addContract')
  export default {
    name: "shopRefund",
    data () {
      return {
        listQuery: {
          status: '',
          detailCode: '',
          goodsName: '',
          date: [],
          page: 1,
          pageSize: 10
        },
        pages: {
          total: 0,
          pages: 0
        },
        listData: [],
        checkList: [],
        isRequestLoading: false,
        isSearchLoading: false,
        imageURL: imageURL,
        isQueryShow: false,
        dateOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now()
          }
        },
        isPopupAddContract: false,
        contractList: [],
        companyList: [],
        isPopupContract: false,
        formContract: {
          id: null,
          contractId: null,
          contractName: '',
          contractCode: '',
          contractType: '',
          contractUrl: [],
          status: 1,
          desc: ''
        },
        submitLoading: false,
        rulesContract: {
          contractId: [
            { required: true, message: '请选择采购合同', trigger: 'change' }
          ],
        }
      }
    },
    computed: {
      ...mapState({
        listKeepAlive: state => state.app.listKeepAlive,
      })
    },
    activated () {
      if (this.listKeepAlive.isInitPageNo) {
        this.$store.commit('changeScrollTop');
        this.listQuery.page = 1
      }
      if (this.listKeepAlive.isReset) {
        this.$refs['listQuery'].resetFields()
      }
      if (this.listKeepAlive.isQueryList) {
        this.getList()
      }
      if (this.listKeepAlive.isQuerySelect) {
        this.toGetContract()
        this.getCompany()
      }
    },
    methods: {
      getList () {
        this.isRequestLoading = true
        let start = ''
        let end = ''
        if (this.listQuery.date.length > 0) {
          start = this.listQuery.date[0] + ' 00:00:00'
          end = this.listQuery.date[1] + ' 23:59:59'
        }
        getOrders({
          status: this.listQuery.status,
          detailCode: this.listQuery.detailCode,
          goodsName: this.listQuery.goodsName,
          start: start,
          end: end,
          page: this.listQuery.page,
          pageSize: this.listQuery.pageSize
        }).then(res => {
          this.isRequestLoading = false
          this.isSearchLoading = false
          if (res.code === 0) {
            this.listData = res.data.list
            this.pages.total = res.data.total
            this.pages.pages = res.data.pages
          }
        }).catch(() => {
          this.isRequestLoading = false
          this.isSearchLoading = false
        })
      },
      pageChange (e) {
        this.listQuery.page = e.current
        this.listQuery.pageSize = e.size
        this.getList()
      },
      toSearchList () {
        this.listQuery.page = 1
        this.isSearchLoading = true
        this.getList()
      },
      changeStatus (status) {
        this.listQuery.status = status
        this.listQuery.page = 1
        this.getList()
      },
      toCancelOrder (id) {
        this.$confirm('是否确认取消该订单?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          toCancelOrder(id).then(res => {
            if (res.code === 0) {
              this.$message({
                message: '订单取消成功',
                type: 'success',
                showClose: true,
              });
              this.getList()
            }
          })
        }).catch(() => {});
      },
      toSendOrderSure (id) {
        this.$confirm('是否确认收货?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          toSendOrderSure(id).then(res => {
            if (res.code === 0) {
              this.$message({
                message: '收货成功',
                type: 'success',
                showClose: true,
              });
              this.getList()
            }
          })
        }).catch(() => {});
      },
      getCompany () {
        getCompany().then(res => {
          if (res.code === 0) {
            this.companyList = res.data
          }
        })
      },
      toGetContract () {
        getContractList().then(res => {
          if (res.code === 0) {
            this.contractList = res.data
          }
        })
      },
      toEditContract (item) {
        this.formContract.id = item.id
        this.formContract.contractId = item.contractId
        this.formContract.contractName = item.contractName
        this.formContract.contractCode = item.contractCode
        this.formContract.contractType = item.contractType
        this.formContract.contractUrl = item.contractUrl
        this.formContract.status = item.contractStatus
        this.formContract.desc = item.contractReason
        this.isPopupContract = true
      },
      submitFormContract (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitLoading = true;

            let data = {}

            if (this.formContract.contractId) {
              data = {
                contractId: this.formContract.contractId,
                contractName: this.contractList.filter(contract => contract.id === this.formContract.contractId)[0].companyName,
                contractUrl: this.contractList.filter(contract => contract.id === this.formContract.contractId)[0].image,
                contractCode: this.contractList.filter(contract => contract.id === this.formContract.contractId)[0].number,
                contractType: this.contractList.filter(contract => contract.id === this.formContract.contractId)[0].type
              }
            } else {
              data = {
                contractId: this.formContract.contractId,
                contractName: '',
                contractUrl: '',
                contractCode: '',
                contractType: ''
              }
            }

            postContract(this.formContract.id, {
              data: data
            }).then(res => {
              this.submitLoading = false
              if (res.code === 0) {
                this.$message({
                  message: '合同修改成功',
                  type: 'success',
                  showClose: true,
                });
                this.isPopupContract = false
                this.getList()
              }
            }).catch(() => {
              this.submitLoading = false
            })
          }
        })
      }
    },
    components: {
      pagination, userMenu, noDataView, addContract
    }
  }
</script>
